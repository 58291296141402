import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

export default function RequirementDocumentForm(props) {
  const [listDataUpload, setListDataUpload] = useState([]);

  useEffect(() => {
    setListDataUpload([createData("Firstname Upload", "PDF", 6.0, 24, "X")]);
  }, []);

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  return (
    <Card>
      {/* Required Document Check list */}
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
            Required Document Check list
          </Typography>
        </Box>
        {/* row */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            {/* 1 */}
            <Typography variant="subtitle1" gutterBottom>
              1. Audited Financial Statements with Notes to the Financial
              Statements for the last 3 years
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Typography
                    sx={{ mt: 2, width: "20%" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    1.1 First Year
                  </Typography>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1 }}>
                      <Button variant="outlined">Upload</Button>
                      &nbsp;or Drop Files
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Typography
                    sx={{ mt: 2, width: "20%" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    1.2 Second Year
                  </Typography>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, mr: 2 }}>
                      <Button variant="outlined">Upload</Button>
                      &nbsp;or Drop Files
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Typography
                    sx={{ mt: 2, width: "20%" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    1.3 Third Year
                  </Typography>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, mr: 2 }}>
                      <Button variant="outlined">Upload</Button>
                      &nbsp;or Drop Files
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 2 */}
            <Typography variant="subtitle1" gutterBottom>
              2. Certificate of Incorporation (COI)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, mr: 2 }}>
                      <Button variant="outlined">Upload</Button>
                      &nbsp;or Drop Files
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 3 */}
            <Typography variant="subtitle1" gutterBottom>
              3. Memorandum of Association (MOA)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, mr: 2 }}>
                      <Button variant="outlined">Upload</Button>
                      &nbsp;or Drop Files
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 4 */}
            <Typography variant="subtitle1" gutterBottom>
              4. Certificate of VAT Registration (Por.Por.20 or 01 or 09)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, mr: 2 }}>
                      <Button variant="outlined">Upload</Button>
                      &nbsp;or Drop Files
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 5 */}
            <Typography variant="subtitle1" gutterBottom>
              5. Registration issues by Ministry of Energy (N.P.105 or N.P. 106)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, mr: 2 }}>
                      <Button variant="outlined">Upload</Button>
                      &nbsp;or Drop Files
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/*  */}
            <Typography variant="subtitle1" gutterBottom>
              6. List of the Shareholders (บอจ.5)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, mr: 2 }}>
                      <Button variant="outlined">Upload</Button>
                      &nbsp;or Drop Files
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 7 */}
            <Typography variant="subtitle1" gutterBottom>
              7. Copy of ID card or passport of CEO/President/MD and
              Shareholders who hold an interest in the company more than 10%
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, mr: 2 }}>
                      <Button variant="outlined">Upload</Button>
                      &nbsp;or Drop Files
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 8 */}
            <Typography variant="subtitle1" gutterBottom>
              8. Other File (if any)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, mr: 2 }}>
                      <Button variant="outlined">Upload</Button>
                      &nbsp;or Drop Files
                    </Box>
                  </Box>
                </Box>
              </Box>
              <TableContainer component={Paper} sx={{ m: 2 }}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>No.</TableCell>
                      <TableCell align="center">File Name</TableCell>
                      <TableCell align="center">File Size</TableCell>
                      <TableCell align="center">Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listDataUpload.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left" component="th" scope="row">
                          {index + 1}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell align="center">{row.fat}</TableCell>
                        <TableCell align="center">{row.carbs}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
