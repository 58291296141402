import * as React from "react";
import {
  Box,
  // Button,
  Card,
  CardContent,
  // FormControl,
  // FormControlLabel,
  // InputLabel,
  // MenuItem,
  Paper,
  // Radio,
  // RadioGroup,
  // Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
// import TextInput from "../TextInput";
// import DateTimePicker from "../DateTimePicker";
// import dayjs from "dayjs";
import { useState } from "react";
import { useEffect } from "react";
// import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";

// import helper from "../../services/helper";
import { RequestFormAPI, SharePointFolder } from "../../services/apigateway";

export default function RequiredDocument(props) {
  // const [listDataUpload, setListDataUpload] = useState([]);
  const [objRegistrationForm, setObjRegistrationForm] = useState({
    RequestFormHeaderId: "",
    RequestFormItemId: "",
    CustomerId: "",
    CompanyNameLocal: "",
    CompanyNameEN: "",
    CompanyNameFormer: "",
    CompanyNameLanguage: "",
    CustomerType: "",
    SupplierType: "",
    BusinessType: "",
    BusinessTypeOthers: "",
    LegalEntityType: "",
    LegalEntityTypeOthers: "",
    IncorporationDate: "",
    TAXNumber: "",
    Website: "",
    HouseNo: "",
    Building: "",
    Room: "",
    Floor: "",
    Street: "",
    District: "",
    SubDistrict: "",
    City: "a0L1m000004GpMOEA0",
    Country: "a0L1m000004GmiSEAS",
    PostalCode: "",
    LocationURL: "",
    Latitude: "",
    Longitude: "",
    MailingIsSameAsRegistered: false,
    MailingHouseNo: "",
    MailingBuilding: "",
    MailingRoom: "",
    MailingFloor: "",
    MailingStreet: "",
    MailingSubDistrict: "",
    MailingDistrict: "",
    MailingCity: "a0L1m000004GpMOEA0",
    MailingCountry: "a0L1m000004GmiSEAS",
    MailingPostalCode: "",
    ContactId: "",
    ContactSalutation: "",
    ContactNickName: "",
    ContactFirstName: "",
    ContactLastName: "",
    ContactPosition: "",
    ContactEmail: "",
    ContactMobileCountryCode: "TH",
    ContactMobileNumber: "",
    ContactPhoneCountryCode: "TH",
    ContactPhoneNumber: "",
    ContactFaxCountryCode: "TH",
    ContactFaxNumber: "",
    InterestedPetroleumAsCustomer: false,
    InterestedPetrochemicalAsCustomer: false,
    InterestedLubeBaseAsCustomer: false,
    InterestedLABIXAsCustomer: false,
    InterestedCrudeAsCustomer: false,
    InterestedLubeBaseAsCustomerDesc: "",
    InterestedLABIXAsCustomerDesc: "",
    InterestedCrudeAsSupplier: false,
    InterestedB100EthanolAsSupplier: false,
    InterestedPetroleumAndComponentAsSupplier: false,
    InterestedPetroleumAndComponentAsSupplierDesc: "",
    InterestedLongResidueAsSupplier: false,
    InterestedNormalParaffinAsSupplier: false,
    InterestedOtherAsCustomer: false,
    InterestedOtherAsCustomerDesc: "",
    InterestedOtherAsSupplier: false,
    InterestedOtherAsSupplierDesc: "",
    MinistryOfEnergyRegistrationNo: "",
    YearsOfExperience: 0,
    EstimatedMonthlyPurchaseQuantity: 0,
    EstimatedMonthlyPurchaseUnit: "",
    StartTradingDate: "",
    DestinationCountries: [
      {
        Id: "",
        Country: "TH - Thailand",
        LocationType: "Local",
      },
    ],
    PurchaseProductFromCompany: "",
    SupplyProductToCompany: "",
    PaymentOverTheAmountOfUSD: "Yes",
    ReasonOfPaymentOverTheAmountOfUSD: "",
    FailedSupplyAgreement: "",
    ReasonOfFailedSupplyAgreement: "",
    HaveTagetsInSanction: "",
    ReasonOfHaveTagetsInSanction: "",
    ViolatingAntiMoneyLaunderingLaws: "No",
    ReasonOfViolatingAntiMoneyLaunderingLaws: "",
    OwnStorageAsCustomer: false,
    RentStorageMoreThanOneYearAsCustomer: false,
    RentStorageLessThanOneYearAsCustomer: false,
    NoStorageAsCustomer: false,
    OwnTransportAsCustomer: false,
    RentTransportMoreThanOneYearAsCustomer: false,
    RentTransportLessThanOneYearAsCustomer: false,
    NoTransportAsCustomer: false,
    OwnStorageAsSupplier: false,
    RentStorageMoreThanOneYearAsSupplier: false,
    RentStorageLessThanOneYearAsSupplier: false,
    NoStorageAsSupplier: false,
    OwnTransportAsSupplier: false,
    RentTransportMoreThanOneYearAsSupplier: false,
    RentTransportLessThanOneYearAsSupplier: false,
    NoTransportAsSupplier: false,
    EnvironmentPolicy: "Yes",
    OccupationalHealthAndSafetyPolicy: "Yes",
    CorporateGovernancePolicy: "Yes",
    HumanRightsPolicy: "Yes",
  });

  const [filesSharePoint, setFilesSharePoint] = useState([]);

  useEffect(() => {
    if (props.formId) {
      masterDataRegistrationForm(props.formId)
    }
    //eslint-disable-next-line
  }, [props.formId]);

  const masterDataRegistrationForm = async (id) => {
    const data = await RequestFormAPI.getById(id)
    setObjRegistrationForm(data)

    if (data.CustomerId) {
      getSharePointFiles(data.CustomerId)
    }
  };

  const getSharePointFiles = async (id) => {
    const results = await SharePointFolder.getFileByAccount(id)
    const filesSharePoint = results.responseData

    const fin = filesSharePoint.find(item => item.Name === "Account_Common_CTR_Financial")
    const coi = filesSharePoint.find(item => item.Name === "Account_Common_CTR_COI")
    const moa = filesSharePoint.find(item => item.Name === "Account_Common_CTR_MOA")
    const shr = filesSharePoint.find(item => item.Name === "Account_Common_CTR_List_Sharehold")
    const vat = filesSharePoint.find(item => item.Name === "Account_Common_CTR_VAT_Reg")
    const moe = filesSharePoint.find(item => item.Name === "Account_Common_CTR_Reg_by_MoE")
    const oth = filesSharePoint.find(item => item.Name === "Account_Common_CTR_Others")

    setFilesSharePoint({
      Account_Common_CTR_Financial: fin || [],
      Account_Common_CTR_COI: coi || [],
      Account_Common_CTR_MOA: moa || [],
      Account_Common_CTR_List_Sharehold: shr || [],
      Account_Common_CTR_VAT_Reg: vat || [],
      Account_Common_CTR_Reg_by_MoE: moe || [],
      Account_Common_CTR_Others: oth || [],
    })
  }

  const findInSharePointFolders = (folderName, fileName) => {
    return filesSharePoint[folderName]
      ?.Files
      ?.find(item => item.Name === fileName)
  }

  const getPathSharePointFolders = (folderName) => {
    return filesSharePoint[folderName]?.Path
  }

  const downloadFile = async (folderName, fileName) => {
    try {
      const path = getPathSharePointFolders(folderName);
      if (!fileName) {
        throw new Error('Filename is not defined');
      }
      const response = await SharePointFolder.downloadFile(path, fileName);
      const responseData = response.responseData;

      if (responseData && responseData.length > 0) {
        const mimeType = 'application/octet-stream';
        const blob = new Blob([responseData], { type: mimeType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  // useEffect(() => {
  //   setListDataUpload([createData("Firstname Upload", "PDF", 6.0, 24, "X")]);
  // }, []);

  // function createData(name, calories, fat, carbs, protein) {
  //   return { name, calories, fat, carbs, protein };
  // }

  return (
    <Card>
      <CardContent>
        <Box sx={{ backgroundColor: "#dbd9d9", m: 1 }}>
          <Typography sx={{ m: 1 }} variant="subtitle1" gutterBottom>
            Required Document Check list
          </Typography>
        </Box>
        {/* row */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start",
          }}
        >
          <Box sx={{ p: 1, m: 1, width: "100%" }}>
            {/* 1 */}
            <Typography variant="subtitle1" gutterBottom>
              1. Audited Financial Statements with Notes to the Financial
              Statements for the last 3 years
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Typography
                    sx={{ mt: 2, width: "20%" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    1.1 First Year
                  </Typography>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl:2, pr: 2, mr: 0.5 }}>
                      {
                        objRegistrationForm
                          ?.Documents
                          ?.FinancialStatement
                          ?.FirstYear
                          && findInSharePointFolders(
                            "Account_Common_CTR_Financial",
                            objRegistrationForm
                              .Documents
                              .FinancialStatement
                              .FirstYear
                          )
                          ? <a href="#!" className="link-download" onClick={
                            (e) => {
                              e.preventDefault()
                              downloadFile(
                                "Account_Common_CTR_Financial",
                                objRegistrationForm
                                  .Documents
                                  .FinancialStatement
                                  .FirstYear
                                  .Filename
                              )
                            }
                          }>
                            <DescriptionOutlinedIcon sx={{ fontSize: "24px", color: "rgb(96, 96, 96)" }} />
                            {
                              objRegistrationForm
                                .Documents
                                .FinancialStatement
                                .FirstYear
                                .Filename
                            }
                          </a>
                          : <span>Not Available</span>
                      }
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Typography
                    sx={{ mt: 2, width: "20%" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    1.2 Second Year
                  </Typography>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl:2, pr: 2, mr: 0.5 }}>
                      {
                        objRegistrationForm
                          ?.Documents
                          ?.FinancialStatement
                          ?.SecondYear
                          && findInSharePointFolders(
                            "Account_Common_CTR_Financial",
                            objRegistrationForm
                              .Documents
                              .FinancialStatement
                              .SecondYear
                          )
                          ? <a href="#!" className="link-download" onClick={
                            (e) => {
                              e.preventDefault()
                              downloadFile(
                                "Account_Common_CTR_Financial",
                                objRegistrationForm
                                  .Documents
                                  .FinancialStatement
                                  .SecondYear
                                  .Filename
                              )
                            }
                          }>
                            <DescriptionOutlinedIcon sx={{ fontSize: "24px", color: "rgb(96, 96, 96)" }} />
                            {
                              objRegistrationForm
                                .Documents
                                .FinancialStatement
                                .SecondYear
                                .Filename
                            }
                          </a>
                          : <span>Not Available</span>
                      }
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Typography
                    sx={{ mt: 2, width: "20%" }}
                    variant="subtitle1"
                    gutterBottom
                  >
                    1.3 Third Year
                  </Typography>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl:2, pr: 2, mr: 0.5 }}>
                      {
                        objRegistrationForm
                          ?.Documents
                          ?.FinancialStatement
                          ?.ThirdYear
                          && findInSharePointFolders(
                            "Account_Common_CTR_Financial",
                            objRegistrationForm
                              .Documents
                              .FinancialStatement
                              .ThirdYear
                          )
                          ? <a href="#!" className="link-download" onClick={
                            (e) => {
                              e.preventDefault()
                              downloadFile(
                                "Account_Common_CTR_Financial",
                                objRegistrationForm
                                  .Documents
                                  .FinancialStatement
                                  .ThirdYear
                                  .Filename
                              )
                            }
                          }>
                            <DescriptionOutlinedIcon sx={{ fontSize: "24px", color: "rgb(96, 96, 96)" }} />
                            {
                              objRegistrationForm
                                .Documents
                                .FinancialStatement
                                .ThirdYear
                                .Filename
                            }
                          </a>
                          : <span>Not Available</span>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 2 */}
            <Typography variant="subtitle1" gutterBottom>
              2. Certificate of Incorporation (COI)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl:2, pr: 2, mr: 0.5 }}>
                      {
                        objRegistrationForm
                          ?.Documents
                          ?.CertificateOfIncorporation
                          && findInSharePointFolders(
                            "Account_Common_CTR_COI",
                            objRegistrationForm
                              .Documents
                              .CertificateOfIncorporation
                          )
                          ? <a href="#!" className="link-download" onClick={
                            (e) => {
                              e.preventDefault()
                              downloadFile(
                                "Account_Common_CTR_COI",
                                objRegistrationForm
                                  .Documents
                                  .CertificateOfIncorporation
                                  .Filename
                              )
                            }
                          }>
                            <DescriptionOutlinedIcon sx={{ fontSize: "24px", color: "rgb(96, 96, 96)" }} />
                            {
                              objRegistrationForm
                                .Documents
                                .CertificateOfIncorporation
                                .Filename
                            }
                          </a>
                          : <span>Not Available</span>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 3 */}
            <Typography variant="subtitle1" gutterBottom>
              3. Memorandum of Association (MOA)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl:2, pr: 2, mr: 0.5 }}>
                      {
                        objRegistrationForm
                          ?.Documents
                          ?.MemorandumOfAssociation
                          && findInSharePointFolders(
                            "Account_Common_CTR_MOA",
                            objRegistrationForm
                              .Documents
                              .MemorandumOfAssociation
                          )
                          ? <a href="#!" className="link-download" onClick={
                            (e) => {
                              e.preventDefault()
                              downloadFile(
                                "Account_Common_CTR_MOA",
                                objRegistrationForm
                                  .Documents
                                  .MemorandumOfAssociation
                                  .Filename
                              )
                            }
                          }>
                            <DescriptionOutlinedIcon sx={{ fontSize: "24px", color: "rgb(96, 96, 96)" }} />
                            {
                              objRegistrationForm
                                .Documents
                                .MemorandumOfAssociation
                                .Filename
                            }
                          </a>
                          : <span>Not Available</span>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 4 */}
            <Typography variant="subtitle1" gutterBottom>
              4. Certificate of VAT Registration (ภ.พ. 20)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl:2, pr: 2, mr: 0.5 }}>
                      {
                        objRegistrationForm
                          ?.Documents
                          ?.CertificateOfVATRegistration
                          && findInSharePointFolders(
                            "Account_Common_CTR_VAT_Reg",
                            objRegistrationForm
                              .Documents
                              .CertificateOfVATRegistration
                          )
                          ? <a href="#!" className="link-download" onClick={
                            (e) => {
                              e.preventDefault()
                              downloadFile(
                                "Account_Common_CTR_VAT_Reg",
                                objRegistrationForm
                                  .Documents
                                  .CertificateOfVATRegistration
                                  .Filename
                              )
                            }
                          }>
                            <DescriptionOutlinedIcon sx={{ fontSize: "24px", color: "rgb(96, 96, 96)" }} />
                            {
                              objRegistrationForm
                                .Documents
                                .CertificateOfVATRegistration
                                .Filename
                            }
                          </a>
                          : <span>Not Available</span>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 5 */}
            <Typography variant="subtitle1" gutterBottom>
              5. List of the Shareholders (บอจ.5)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl:2, pr: 2, mr: 0.5 }}>
                      {
                        objRegistrationForm
                          ?.Documents
                          ?.ListOfShareholders
                          && findInSharePointFolders(
                            "Account_Common_CTR_List_Sharehold",
                            objRegistrationForm
                              .Documents
                              .ListOfShareholders
                          )
                          ? <a href="#!" className="link-download" onClick={
                            (e) => {
                              e.preventDefault()
                              downloadFile(
                                "Account_Common_CTR_List_Sharehold",
                                objRegistrationForm
                                  .Documents
                                  .ListOfShareholders
                                  .Filename
                              )
                            }
                          }>
                            <DescriptionOutlinedIcon sx={{ fontSize: "24px", color: "rgb(96, 96, 96)" }} />
                            {
                              objRegistrationForm
                                .Documents
                                .ListOfShareholders
                                .Filename
                            }
                          </a>
                          : <span>Not Available</span>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 6 */}
            <Typography variant="subtitle1" gutterBottom>
              6. Registration issueก by Ministry of Energy (For petroleum)
              <Box sx={{ textIndent: 20 }}>
                <Box sx={{ display: "flex", flexDirecttion: "row", mt: 2 }}>
                  <Box className="upload-box" sx={{ ml: 2 }}>
                    <Box sx={{ p: 1, pl:2, pr: 2, mr: 0.5 }}>
                      {
                        objRegistrationForm
                          ?.Documents
                          ?.RegistrationIssuedByMinistryOfEnergy
                          && findInSharePointFolders(
                            "Account_Common_CTR_Reg_by_MoE",
                            objRegistrationForm
                              .Documents
                              .RegistrationIssuedByMinistryOfEnergy
                          )
                          ? <a href="#!" className="link-download" onClick={
                            (e) => {
                              e.preventDefault()
                              downloadFile(
                                "Account_Common_CTR_Reg_by_MoE",
                                objRegistrationForm
                                  .Documents
                                  .RegistrationIssuedByMinistryOfEnergy
                                  .Filename
                              )
                            }
                          }>
                            <DescriptionOutlinedIcon sx={{ fontSize: "24px", color: "rgb(96, 96, 96)" }} />
                            {
                              objRegistrationForm
                                .Documents
                                .RegistrationIssuedByMinistryOfEnergy
                                .Filename
                            }
                          </a>
                          : <span>Not Available</span>
                      }
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Typography>
            {/* 7 */}
            <Typography variant="subtitle1" gutterBottom>
              7. Other File (If any)
              <TableContainer component={Paper} sx={{ m: 2 }}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" width="120px">No.</TableCell>
                      <TableCell align="left">File Name</TableCell>
                      <TableCell align="center" width="150px">File Size</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {objRegistrationForm?.Documents?.Others?.map((item, index) => {
                      const files = findInSharePointFolders("Account_Common_CTR_Others", item);
                      console.log(files);
                      if (files) {
                        return (
                          <TableRow key={index}>
                            <TableCell align="center" component="th" scope="row">
                              {index + 1}
                            </TableCell>
                            <TableCell align="left" component="th" scope="row">
                              <a
                                className="link-download"
                                href="#!"
                                onClick={
                                  (e) => {
                                    e.preventDefault()
                                    downloadFile("Account_Common_CTR_Others", files.Name)
                                  }
                                }
                              >
                                <DescriptionOutlinedIcon sx={{ fontSize: "24px", color: "rgb(96, 96, 96)" }} />
                                {files.Filename}
                              </a>
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                              {files.Length}
                            </TableCell>
                          </TableRow>
                        )
                      } else {
                        return null;
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
