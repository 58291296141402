import * as React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// authen-pages
import AuthGuard from "./layouts/AuthGuard";
import UnAuthGuard from "./layouts/UnAuthGuard";
import Basiclayout from "./layouts/Basiclayout";

// screen pages
import PageNotFoundScreen from "./pages/PageNotFoundScreen";
import LoginScreen from "./pages/LoginScreen";
import LogoutScreen from "./pages/LogoutScreen";
import MainScreen from "./pages/MainScreen";
import RegisterScreen from "./pages/RegisterScreen";
import CounterPartyRegistration from "./pages/CounterPartyRegistration";
import {
  ForgotPasswordScreen,
  ChangePasswordScreen,
  EmailResetPasswordScreen,
  VerifySecurityQuestionScreen,
} from "./pages/ResetPasswordScreen";
import PreRegisterScreen from "./pages/PreRegisterScreen";
import SetPasswordScreen from "./pages/SetPasswordScreen";
import RequestViewScreen from "./pages/RequestViewScreen";
import RequestEditScreen from "./pages/RequestEditScreen";
import PerformanceBondScreen from "./pages/PerformanceBondScreen";
import AnnualReviewScreen from "./pages/AnnualReviewScreen";
import PreScreeningScreen from "./pages/PreScreeningScreen";
import ExtensionScreen from "./pages/ExtensionScreen";
import DocumentsScreen from "./pages/DocumentsScreen";
import RequestEmailScreen from "./pages/RequestEmailScreen";

// css-layouts
import "./styles/App.css";
import UploadDocumentScreen from "./pages/UploadDocumentScreen";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* UnAuth-Page */}
        <Route path="*" element={<PageNotFoundScreen />} />
        <Route path="/" element={<UnAuthGuard component={<LoginScreen />} />} />
        <Route
          path="/one-time-access"
          element={<Basiclayout component={<RequestEmailScreen />} />}
        />
        <Route
          path="/one-time-access/:accountId"
          element={<Basiclayout component={<RequestEmailScreen />} />}
        />
        <Route
          path="/register"
          element={<AuthGuard component={<RegisterScreen />} />}
        />
        <Route
          path="/counter-register"
          element={<UnAuthGuard component={<CounterPartyRegistration />} />}
        />
        <Route
          path="/forgot-password"
          element={<UnAuthGuard component={<ForgotPasswordScreen />} />}
        />
        <Route
          path="/email-reset-password"
          element={<UnAuthGuard component={<EmailResetPasswordScreen />} />}
        />
        <Route
          path="/verify-security-question"
          element={<UnAuthGuard component={<VerifySecurityQuestionScreen />} />}
        />
        <Route
          path="/change-password"
          element={<UnAuthGuard component={<ChangePasswordScreen />} />}
        />
        <Route
          path="/pre-register"
          element={<UnAuthGuard component={<PreRegisterScreen />} />}
        />
        <Route
          path="/initial-password"
          element={<UnAuthGuard component={<SetPasswordScreen />} />}
        />
        {/* Auth-Page */}
        <Route
          path="/main"
          element={<AuthGuard component={<MainScreen />} />}
        />
        <Route
          path="/request/view/:id"
          element={<AuthGuard component={<RequestViewScreen />} />}
        />
        <Route
          path="/request/edit/:id"
          element={<AuthGuard component={<RequestEditScreen />} />}
        />
        <Route
          path="/reduce-performance-bond"
          element={<AuthGuard component={<PerformanceBondScreen />} />}
        />
        <Route
          path="/annual-review"
          element={<AuthGuard component={<AnnualReviewScreen />} />}
        />
        <Route
          path="/annual-review/:id"
          element={<AuthGuard component={<AnnualReviewScreen />} />}
        />
        <Route
          path="/pre-screening"
          element={<AuthGuard component={<PreScreeningScreen />} />}
        />
        <Route
          path="/pre-screening/:id"
          element={<AuthGuard component={<PreScreeningScreen />} />}
        />
        <Route
          path="/extend/:accountId"
          element={<AuthGuard component={<ExtensionScreen />} />}
        />
        <Route
          path="/extend/edit/:id"
          element={<AuthGuard component={<ExtensionScreen />} />}
        />
        <Route
          path="/documents"
          element={<AuthGuard component={<DocumentsScreen />} />}
        />
        {/* phase 2 wait uncomments P'May */}
        {/* <Route
          path="/upload-documents/:id"
          element={<AuthGuard component={<UploadDocumentScreen />} />}
        /> */}
        <Route
          path="/logout"
          element={<AuthGuard component={<LogoutScreen />} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
