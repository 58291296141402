/* eslint-disable*/
import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CheckIcon from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Box, Button, CircularProgress } from "@mui/material";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import {
  ChangeMasterFileAPI,
  SaleForceUploadFile,
  SharePointFolder,
} from "../services/apigateway";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import RequiredUploadDocuments from "../components/UploadDocuments/RequiredUploadDocuments";

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  fontWeight: "bold",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
  }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient(0deg, rgba(184,0,200,1) 0%, rgba(161,0,175,1) 43%, rgba(184,0,200,1) 100%);",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

function ColorlibStepIcon(props) {
  let { completed } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed }}
      style={{
        color: "white",
        backgroundColor: "rgba(184,0,200,1)",
        border: "1px solid #b5b5b5",
      }}
    >
      {completed ? <CheckIcon /> : <>{props.icon}</>}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

export default function UploadDocumentScreen() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [objChangeMasterFile, setObjChangeMasterFile] = React.useState({});
  const [showTableOther, setShowTableOther] = React.useState(false);
  const [showTableOtherAudited, setShowTableOtherAudited] =
    React.useState(false);
  const [selectedOther, setSelectedOther] = React.useState([]);
  const [selectedAudited, setSelectedAudited] = React.useState([]);
  const [pathsSharePoint, setPathsSharePoint] = React.useState([]);
  const [isEdited, setIsEdited] = React.useState(false);

  React.useEffect(() => {
    setActiveStep(0);
    const element = document.querySelector(".MuiStepLabel-iconContainer");
    if (element) {
      const elementText = document.querySelector(".css-1am1g1a");
      if (elementText) {
        elementText.textContent = "3";
      }
    }

    // Call your RequestForm function here
    const fetchData = async () => {
      try {
        setLoading(true);
        if (id) {
          const response = await SharePointFolder.getPathByAccount(id);
          const responseMaster =
            await ChangeMasterFileAPI.getChangeMasterFilelist(id);

          const filesSharePoint = response.responseData;

          const oth = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_Others"
          );
          const othAudited = filesSharePoint.find(
            (item) => item.Name === "Account_Common_CTR_Financial"
          );

          if (responseMaster && responseMaster.records.length > 0) {
            setIsEdited(true);
          } else {
            setIsEdited(false);
          }

          setPathsSharePoint({
            Account_Common_CTR_Financial: othAudited.Path || "",
            Account_Common_CTR_Others: oth.Path || "",
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const uploadFileToSaleForceAttachment = async (file) => {
    try {
      const uploadResponse = await SaleForceUploadFile.uploadChangeMasterFile(
        id,
        file
      );
      return uploadResponse;
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
    }
  };

  const uploadFileToFinancial = async (file, namefolder) => {
    try {
      if (file && namefolder) {
        const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
          file,
        ]);
        console.log("Upload SP financial res:", uploadResponse);
      }
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
      Swal.fire({
        title: "Error while uploading:" + error,
        icon: "error",
        position: "center",
      });
    }
  };

  const uploadToFileToOther = async (file, namefolder) => {
    try {
      if (file && namefolder) {
        const uploadResponse = await SharePointFolder.uploadFile(namefolder, [
          file,
        ]);
        console.log("Upload SP other res:", uploadResponse);
      }
    } catch (error) {
      console.error("Error while uploading:", error);
      if (error.response) {
        console.error("Response:", error.response);
      }
      Swal.fire({
        title: "Error while uploading:" + error,
        icon: "error",
        position: "center",
      });
    }
  };

  const updateFiles = async () => {
    try {
      const FileOther = document.getElementById("FileOther2");
      const FileOtherAudited = document.getElementById("FileOther2Audited");
      const selectedFileOther = FileOther.files;
      const selectedFileOtherAudited = FileOtherAudited.files;

      if (selectedFileOtherAudited.length > 0) {
        for (const file of selectedFileOtherAudited) {
          await uploadFileToFinancial(
            file,
            pathsSharePoint.Account_Common_CTR_Financial
          );
        }
      }
      if (selectedFileOther.length > 0) {
        for (const file of selectedFileOther) {
          await uploadToFileToOther(
            file,
            pathsSharePoint.Account_Common_CTR_Others
          );
        }
      }

      const fileListMaster1 = Array.from(FileOtherAudited.files);
      const fileListMaster2 = Array.from(FileOther.files);

      const masterChangeFileList = [
        ...(fileListMaster1.length > 0
          ? fileListMaster1.map((itemA) => {
              return {
                name: itemA.name,
                type: "Account_Common_CTR_Financial",
              };
            })
          : []),
        ...(fileListMaster2.length > 0
          ? fileListMaster2.map((itemB) => {
              return {
                name: itemB.name,
                type: "Account_Common_CTR_Others",
              };
            })
          : []),
      ];

      let responseMS = await uploadFileToSaleForceAttachment(
        masterChangeFileList
      );

      FileOther.value = "";
      FileOtherAudited.value = "";

      setShowTableOther(false);
      setShowTableOtherAudited(false);
      return true;
    } catch (error) {
      console.error("Error in handling file upload:", error);
      return false;
    }
  };

  const handleSubmitFiles = async function () {
    try {
      const allFilesExist = await checkFilesExist();

      if (!allFilesExist) {
        return;
      }

      // If files are selected, initiate the file upload process and handle submission
      setLoading(true);
      Swal.fire({
        title: "Do you want to upload file?",
        icon: "question",
        position: "center",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: "Cancel",
        cancelButtonColor: "red",
      }).then(async (result) => {
        if (result.isConfirmed) {
          let resChecks = await updateFiles();
          console.log(resChecks);

          Swal.fire({
            title: resChecks ? "Upload Complete" : "Upload Failed",
            icon: resChecks ? "success" : "error",
            showConfirmButton: true,
          }).then(async (result) => {
            console.log(result);
            if (result.isConfirmed || result.isDismissed) {
              document
                .getElementById("check-form-data-register-save-draft")
                .click();
              setSelectedOther([]);
              setSelectedAudited([]);
              setLoading(false);
              navigate("/main");
            }
          });
        }
        setLoading(false);
      });
    } catch (error) {
      console.error("Error in handling form submission:", error);
    }
  };

  const checkFilesExist = async () => {
    const FileAudited = document.getElementById("FileOther2Audited");
    const FileOther = document.getElementById("FileOther2");

    let filesNotExist = [];

    if (FileAudited.files.length > 0) {
      for (let i = 0; i < FileAudited.files.length; i++) {
        filesNotExist.push(FileAudited.files[i].name);
      }
    }

    if (FileOther.files.length > 0) {
      for (let i = 0; i < FileOther.files.length; i++) {
        filesNotExist.push(FileOther.files[i].name);
      }
    }

    if (filesNotExist.length > 0) {
      Swal.fire({
        title: "Warning",
        text: "Files are not exists: " + filesNotExist.join(", "),
        icon: "warning",
        position: "center",
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "OK",
      });
    }

    return filesNotExist.length > 0;
  };

  const renderRequirmentScreen = (edit) => {
    return (
      <RequiredUploadDocuments
        isEdited={edit}
        objChangeMasterFile={objChangeMasterFile}
        setShowTableOther={setShowTableOther}
        setShowTableOtherAudited={setShowTableOtherAudited}
        setObjChangeMasterFile={setObjChangeMasterFile}
        setSelectedOther={setSelectedOther}
        setSelectedAudited={setSelectedAudited}
        selectedOther={selectedOther}
        selectedAudited={selectedAudited}
      />
    );
  };

  return (
    <>
      <Stack sx={{ width: "100%", margin: 5 }} spacing={4}>
        <Stepper alternativeLabel connector={<ColorlibConnector />}>
          <Step>
            <StepLabel StepIconComponent={ColorlibStepIcon}>
              Upload Documents
            </StepLabel>
          </Step>
        </Stepper>

        {/* render upload file list */}
        <Box>{renderRequirmentScreen(isEdited)}</Box>

        {/* button-save */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading && (
            <Backdrop sx={{ color: "#fff", zIndex: 2 }} open={true}>
              <span
                style={{
                  justifyContent: "center",
                  position: "fixed",
                  top: "40%",
                }}
              >
                <Box
                  sx={{ p: 2, m: 2, justifyContent: "center", display: "flex" }}
                >
                  <CircularProgress
                    style={{
                      width: 100,
                      height: 100,
                      color: "rgba(184,0,200,1)",
                    }}
                  />
                </Box>
                <Box sx={{ justifyContent: "center", display: "flex" }}>
                  <label style={{ fontSize: 22, fontWeight: "bold" }}>
                    {" "}
                    Please wait...
                  </label>
                </Box>
              </span>
            </Backdrop>
          )}
          <Button
            sx={{ p: 1, m: 1 }}
            variant="outlined"
            color="error"
            onClick={() => {
              Swal.fire({
                title: "Do you want to leave this page?",
                icon: "question",
                position: "center",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Yes",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigate(-1);
                }
              });
            }}
          >
            Cancel
          </Button>
          <Button
            sx={{ p: 1, m: 1 }}
            variant="contained"
            disabled={loading}
            onClick={() => {
              handleSubmitFiles();
            }}
          >
            Submit
          </Button>
        </Box>
      </Stack>
    </>
  );
}
