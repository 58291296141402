import * as React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { visuallyHidden } from "@mui/utils";

import symbols from "../styles/thirdparty/salesforce/icons/utility-sprite/svg/symbols.svg";
import "../styles/thirdparty/salesforce/styles/salesforce-lightning-design-system.css";
import "../styles/Main.css";
import { useNavigate } from "react-router";

import { RequestFormAPI } from "../services/apigateway";
import { Typography } from "@mui/material";
import helper from "../services/helper";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "requestNumber",
    numeric: false,
    disablePadding: false,
    label: "Request Number",
  },
  {
    id: "requestType",
    numeric: false,
    disablePadding: false,
    label: "Request Type",
  },
  {
    id: "isCustomer",
    numeric: false,
    disablePadding: false,
    label: "Customer",
  },
  {
    id: "isSupplier",
    numeric: false,
    disablePadding: false,
    label: "Supplier",
  },
  {
    id: "productType",
    numeric: false,
    disablePadding: false,
    label: "Product Type",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "traderOwner",
    numeric: false,
    disablePadding: false,
    label: "Trader Owner",
  },
];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#E0E0E0",
    color: "#101010",
    fontWeight: "700",
    border: "1px solid #B0B0B0",
  },
  [`&.${tableCellClasses.body}`]: {},
}));

function EnhancedTableHead(props) {
  const {
    //onSelectAllClick,
    order,
    orderBy,
    //numSelected,
    //rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedSearchModal({ criteria, onSubmitSearch }) {
  const customStyles = {
    content: {
      top: "30%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const [searchText, setSearchText] = React.useState(criteria.searchText);
  const [counterPartyType, setCounterPartyType] = React.useState(
    criteria.counterPartyType
  );
  const [requestType, setRequestType] = React.useState(criteria.requestType);
  const [productType, setProductType] = React.useState(criteria.productType);
  const [status, setStatus] = React.useState(criteria.status);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const submitSearch = function (e) {
    e.preventDefault();

    if (onSubmitSearch) {
      e.detail = {
        searchText: searchText,
        counterPartyType: counterPartyType,
        requestType: requestType,
        productType: productType,
        status: status,
      };

      onSubmitSearch(e);
    }
    setIsOpen(false);
  };

  return (
    <React.Fragment>
      <div className="slds-form-element">
        <div
          className="
          slds-form-element__control
          slds-input-has-icon
          slds-input-has-icon_left"
        >
          <svg
            className="
              slds-icon
              slds-input__icon
              slds-input__icon_left
              slds-icon-text-default"
            aria-hidden="true"
          >
            <use xlinkHref={`${symbols}#search`}></use>
          </svg>
          <input
            type="text"
            // id="searchText"
            // name="searchText"
            placeholder="Search"
            className="slds-input search"
            value={searchText}
            onClick={() => setIsOpen(true)}
            readOnly
          />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        shouldCloseOnOverlayClick={true}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="MainScreen_modal-title">
          {/* <svg
            className="
              slds-icon slds-input__icon
              slds-input__icon_left
              slds-icon-text-default"
            aria-hidden="true"
          >
            <use xlinkHref={`${symbols}#search`}></use>
          </svg>
          <span>Search by Request Number, Status, etc.</span> */}
          <div
            className="
            slds-form-element__control
            slds-input-has-icon
            slds-input-has-icon_left"
          >
            <svg
              className="
                slds-icon
                slds-input__icon
                slds-input__icon_left
                slds-icon-text-default"
              aria-hidden="true"
            >
              <use xlinkHref={`${symbols}#search`}></use>
            </svg>
            <input
              type="text"
              id="searchText"
              name="searchText"
              placeholder="Search by Request Number, Status, etc."
              className="slds-input search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        </div>
        <form className="MainScreen_modal-body" onSubmit={submitSearch}>
          <div className="slds-grid slds-gutters slds-wrap">
            <div className="slds-col slds-size_1-of-2 slds-p-top_small">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="counterPartyType"
                >
                  <i className="bi bi-people-fill"></i> Counterparty Type
                </label>
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      id="counterPartyType"
                      name="counterPartyType"
                      value={counterPartyType}
                      onChange={(e) => setCounterPartyType(e.target.value)}
                    >
                      <option value="">Select…</option>
                      <option value="Customer">Customer</option>
                      <option value="Supplier">Supplier</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="slds-col slds-size_1-of-2 slds-p-top_small">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="requestType"
                >
                  <i className="bi bi-file-earmark-text"></i> Request Type
                </label>
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      id="requestType"
                      name="requestType"
                      value={requestType}
                      onChange={(e) => setRequestType(e.target.value)}
                    >
                      <option value="">Select…</option>
                      <option value="Initial">Initial</option>
                      <option value="Extend">Extend</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="slds-col slds-size_1-of-2 slds-p-top_small">
              <div className="slds-form-element">
                <label
                  className="slds-form-element__label"
                  htmlFor="productType"
                >
                  <i className="bi bi-box"></i> Product Type
                </label>
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      id="productType"
                      name="productType"
                      value={productType}
                      onChange={(e) => setProductType(e.target.value)}
                    >
                      <option value="">Select…</option>
                      <option value="Crude">Crude</option>
                      <option value="Petroleum Products">
                        Petroleum Products
                      </option>
                      <option value="Petrochemical Products">
                        Petrochemical Products
                      </option>
                      <option value="Lube Base Products">
                        Lube Base Products
                      </option>
                      <option value="LABIX Products">LABIX Products</option>
                      <option value="B100/Ethanol">B100/Ethanol</option>
                      <option value="Petroleum and Components">
                        Petroleum and Components
                      </option>
                      <option value="Normal Paraffin">Normal Paraffin</option>
                      <option value="Other Products">Other Products</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="slds-col slds-size_1-of-2 slds-p-top_small">
              <div className="slds-form-element">
                <label className="slds-form-element__label" htmlFor="status">
                  <i className="bi bi-flag-fill"></i> Status
                </label>
                <div className="slds-form-element__control">
                  <div className="slds-select_container">
                    <select
                      className="slds-select"
                      id="status"
                      name="status"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="">Select…</option>
                      <option value="Registered">Registered</option>
                      <option value="Review">Review</option>
                      <option value="Request Additional Information and Documents">
                        Request Documents
                      </option>
                      <option value="Verify Counterparty">
                        Verify Counterparty
                      </option>
                      <option value="In Approval Process">
                        In Approval Process
                      </option>
                      <option value="Completed">Completed</option>
                      <option value="Rejected">Rejected</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="
              slds-col
              slds-size_1-of-1
              slds-p-top_large
              slds-text-align_center"
            >
              <button
                className="slds-button slds-button_outline-brand"
                type="submit"
              >
                Search
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </React.Fragment>
  );
}

export default function MainScreen() {
  const navigate = useNavigate();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [requests, setRequests] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [criteria, setCriteria] = React.useState({
    searchText: "",
    counterPartyType: "",
    requestType: "",
    productType: "",
    status: "",
  });

  React.useEffect(() => {
    (async function () {
      const requests = await RequestFormAPI.findAll();
      const userInfo = helper.Auth.getTokenData();
      let filteredRequest;
      if (userInfo.forAccount) {
        filteredRequest = requests.filter(
          (item) => item.CustomerId === userInfo.forAccount
        );
        setRequests(filteredRequest);
      } else {
        filteredRequest = requests;
        setRequests(requests);
      }

      const headers = groupByRequestHeader(filteredRequest);
      const unsubmitted = findUnsubmittedRegistration(headers);
      const hasUnsubmitted = unsubmitted.length > 0;

      if (hasUnsubmitted && Object.keys(headers).length === 1) {
        const firstRequestHeaderId = unsubmitted[0];
        const firstRow = headers[firstRequestHeaderId][0];
        navigate(`/register`, {
          state: {
            registration: {
              Id: firstRow.RequestFormHeaderId,
              Step: firstRow.RegistrationStep,
            },
          },
        });
      }

      // const unsubmittedInitialRequest = requests.filter((request) => {
      //   return isUnSubmittedInitialRequest(request);
      // });

      // if (unsubmittedInitialRequest.length > 0) {
      //   const firstRow = unsubmittedInitialRequest[0]
      //   navigate(`/register`, {
      //     state: {
      //       registration: {
      //         Id: firstRow.RequestFormHeaderId,
      //         Step: firstRow.RegistrationStep
      //       }
      //     }
      //   });
      // }
    })();
  }, [navigate]);

  const groupByRequestHeader = (requestItems) => {
    const headers = {};
    requestItems.forEach((item) => {
      if (Array.isArray(headers[item.RequestFormHeaderId])) {
        headers[item.RequestFormHeaderId].push(item);
      } else {
        headers[item.RequestFormHeaderId] = [item];
      }
    });
    return headers;
  };

  const findUnsubmittedRegistration = (requestHeaders) => {
    const unsubmitted = [];
    Object.keys(requestHeaders).forEach((requestHeaderId) => {
      const allUnReviewByTrader = requestHeaders[requestHeaderId].every(
        (item) => {
          return item.Status === "Registered" && item.RequestType === "Initial";
        }
      );
      const allUnSubmitted = requestHeaders[requestHeaderId].every((item) => {
        return item.IsSubmitted === false && item.RequestType === "Initial";
      });
      if (allUnReviewByTrader && allUnSubmitted) {
        unsubmitted.push(requestHeaderId);
      }
    });
    return unsubmitted;
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, row) => {
    let newSelected = [];
    setSelected(newSelected);

    const headers = groupByRequestHeader(requests);
    const unsubmitted = findUnsubmittedRegistration(headers);

    if (row.Status === "Request Documents") {
      navigate(`/pre-screening/${row.Id}`);
    } else if (row.RequestType === "Extend" && row.Status === "Draft") {
      navigate(`/extend/edit/${row.RequestFormHeaderId}`);
    } else if (row.RequestType === "Initial" && row.Status === "Draft") {
      navigate(`/register`, {
        state: {
          registration: {
            Id: row.RequestFormHeaderId,
            Step: row.RegistrationStep,
          },
        },
      });
    } else if (unsubmitted.includes(row.RequestFormHeaderId)) {
      navigate(`/register`, {
        state: {
          registration: {
            Id: row.RequestFormHeaderId,
            Step: row.RegistrationStep,
          },
        },
      });
    } else {
      navigate(`/request/view/${row.Id}`);
    }
  };

  // const isUnSubmittedInitialRequest = (row) => {
  //   return row.RequestType?.toLowerCase().trim() === "initial"
  //     && (!row.Status
  //       || row.Status?.toLowerCase().trim() === "registered")
  //     && !row.IsSubmitted
  // }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmitSearch = (e) => {
    setCriteria(e.detail);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filterCriteria = function (data, criteria) {
    let result = data;
    if (criteria.counterPartyType) {
      result = result.filter((item) => item.Type === criteria.counterPartyType);
    }
    if (criteria.requestType) {
      result = result.filter(
        (item) => item.RequestType === criteria.requestType
      );
    }
    if (criteria.productType) {
      result = result.filter(
        (item) => item.Product.indexOf(criteria.productType) > -1
      );
    }
    if (criteria.status) {
      result = result.filter((item) => item.Status === criteria.status);
    }
    if (criteria.searchText) {
      result = result.filter((item) => {
        const type = item.Type.indexOf(criteria.searchText) > -1;
        const requestType = item.RequestType.indexOf(criteria.searchText) > -1;
        const productType = item.Product.indexOf(criteria.searchText) > -1;
        const status = item.Status.indexOf(criteria.searchText) > -1;
        return type || requestType || productType || status;
      });
    }
    setRows(result);
    return result;
  };

  const visibleRows = React.useMemo(
    () =>
      stableSort(
        filterCriteria(requests, criteria),
        getComparator(order, orderBy)
      ),
    [order, orderBy, criteria, requests]
  );

  return (
    <Container maxWidth="xl" className="MainScreen">
      <div
        className="
        slds-p-around_medium
        slds-text-align_center
        slds-size_1-of-1
        slds-medium-size_1-of-2
        slds-large-size_1-of-3
        box-absolute-center"
      >
        <EnhancedSearchModal
          className="search-modal"
          criteria={criteria}
          onSubmitSearch={handleSubmitSearch}
        />
      </div>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.Id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  row.Status =
                    row.Status ===
                    "Request Additional Information and Documents"
                      ? "Request Documents"
                      : row.Status;

                  if (index === 0) {
                    return (
                      <>
                        <TableRow
                          sx={{ backgroundColor: "#F0F0F0" }}
                          key={row.CustomerId}
                        >
                          <TableCell colSpan={7} sx={{ paddingBottom: "4px" }}>
                            <Typography
                              sx={{ fontSize: "rem", fontWeight: 700 }}
                            >
                              <i className="bi bi-caret-right-fill"></i>{" "}
                              {row.Customer}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.Id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell component="td" id={labelId} scope="row">
                            {row.Name}
                          </TableCell>
                          <TableCell>
                            {row.RequestType && (
                              <Chip
                                color="primary"
                                variant="outlined"
                                label={row.RequestType}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {row.Type === "Customer" ? (
                              <i className="bi bi-check-circle-fill"></i>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>
                            {row.Type === "Supplier" ? (
                              <i className="bi bi-check-circle-fill"></i>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>{row.Product}</TableCell>
                          <TableCell>
                            {row.Status && (
                              <Chip
                                color="primary"
                                variant="outlined"
                                label={row.Status}
                              />
                            )}
                          </TableCell>
                          <TableCell>{row.Trader}</TableCell>
                        </TableRow>
                      </>
                    );
                  } else if (
                    index > 0 &&
                    row.CustomerId !== visibleRows[index - 1]?.CustomerId
                  ) {
                    return (
                      <>
                        <TableRow
                          sx={{ backgroundColor: "#F0F0F0" }}
                          key={row.CustomerId}
                        >
                          <TableCell colSpan={7} sx={{ paddingBottom: "4px" }}>
                            <Typography
                              sx={{ fontSize: "1rem", fontWeight: 700 }}
                            >
                              <i className="bi bi-caret-right-fill"></i>{" "}
                              {row.Customer}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.Id}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell component="td" id={labelId} scope="row">
                            {row.Name}
                          </TableCell>
                          <TableCell>
                            {row.RequestType && (
                              <Chip
                                color="primary"
                                variant="outlined"
                                label={row.RequestType}
                              />
                            )}
                          </TableCell>
                          <TableCell>
                            {row.Type === "Customer" ? (
                              <i className="bi bi-check-circle-fill"></i>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>
                            {row.Type === "Supplier" ? (
                              <i className="bi bi-check-circle-fill"></i>
                            ) : (
                              ""
                            )}
                          </TableCell>
                          <TableCell>{row.Product}</TableCell>
                          <TableCell>
                            {row.Status && (
                              <Chip
                                color="primary"
                                variant="outlined"
                                label={row.Status}
                              />
                            )}
                          </TableCell>
                          <TableCell>{row.Trader}</TableCell>
                        </TableRow>
                      </>
                    );
                  } else {
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.Id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell component="td" id={labelId} scope="row">
                          {row.Name}
                        </TableCell>
                        <TableCell>
                          {row.RequestType && (
                            <Chip
                              color="primary"
                              variant="outlined"
                              label={row.RequestType}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {row.Type === "Customer" ? (
                            <i className="bi bi-check-circle-fill"></i>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell>
                          {row.Type === "Supplier" ? (
                            <i className="bi bi-check-circle-fill"></i>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        <TableCell>{row.Product}</TableCell>
                        <TableCell>
                          {row.Status && (
                            <Chip
                              color="primary"
                              variant="outlined"
                              label={row.Status}
                            />
                          )}
                        </TableCell>
                        <TableCell>{row.Trader}</TableCell>
                      </TableRow>
                    );
                  }
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: 43 * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            style={{ display: "none" }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </Container>
  );
}
